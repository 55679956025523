import { FunctionComponent, Dispatch, useState, SetStateAction, useMemo } from 'react'
import { css, cx } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import Big from 'big.js'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import type { LoyaltySessionState } from '@/components/Checkout'
import PopupModalTemplate from '@/components/PopupModalTemplate'
import type { Delivery, OperationalMenu, VenueOrderingMethod } from '@/libs/helpers/adapters'
import { Bill, Loyalty, VenueLoyalty, TBPaymentType, PaymentMethod } from '@/libs/helpers/apiClient'
import { formatCurrency } from '@/libs/helpers/formatters'
import { OrderMethod, getOrderMethodSettings } from '@/libs/helpers/utils'
import type { OrderItem } from '@/libs/helpers/utils'
import { useModal } from '@/providers/modal'
import { CircularLoader, Button, Text } from '@/tbui'
import { CartBody } from './CartBody'
import { CartHeader } from './CartHeader'

export enum CartWidgetVariant {
  CHECKOUT = 'CHECKOUT',
  MENU = 'MENU',
}

// TODO: clean up CartProps
export interface CartProps {
  variant: CartWidgetVariant
  onItemsChange: (items: OrderItem[]) => Promise<void>
  setIncludeCutlery?: Dispatch<SetStateAction<boolean>>
  onSubmit: () => void
  offersLoyaltyRewards: boolean
  userRewards: Loyalty | null
  onClearLoyalty: () => void
  onClearCart: () => Promise<void>
  loyaltySession?: LoyaltySessionState
  onSelectReward?: (selectedReward: string | null) => Promise<void>
  hasBoxShadow?: boolean
  loyaltyConfig: VenueLoyalty
  venue: {
    isASAPAvailable: boolean
    isSchedulingAvailable: boolean
    disableTip?: boolean
    name: string
    venueOrderingMethods: VenueOrderingMethod[]
    active: boolean
    venueReopensAt?: string | null
    includeCutlery?: boolean
    venueXRefID: string
  }
  cart: {
    bill: Bill
    delivery?: Delivery
    isCartLoading: boolean
    scheduledFor?: string
    appliedLoyaltyRewardName?: string
    items: OrderItem[]
    orderMethod: OrderMethod
    discountedOrderItemID?: string
  }
  operationalMenu: OperationalMenu
  tbPaymentType?: TBPaymentType | null
  paymentMethod?: PaymentMethod | null
  showPayrixiFrameFF?: boolean
  currency?: string
}

const containerStyles = (theme: Theme): string => css`
  display: grid;
  height: 100%;
  z-index: 9;
  max-width: 100%;
  width: 100%;
  align-items: start;
  position: relative;

  &:before {
    @media (min-width: ${theme.shape.CONTAINER}) {
      background: ${theme.palette.WHITE};
      position: absolute;
      right: -16px;
      width: 16px;
      content: '';
      bottom: 0;
      top: 0;
    }
  }

  &:after {
    @media (min-width: ${theme.shape.CONTAINER}) {
      right: -16px;
    }
  }

  &.has-shadow {
    box-shadow: ${theme.shape.SHADOW};
  }
`

const contentStyles = (theme: Theme): string => css`
  padding: 0 1rem;
  ${theme.mixin.fluidRange(
    {
      prop: 'padding-left',
      fromSize: '16px',
      toSize: '36px',
    },
    {
      prop: 'padding-right',
      fromSize: '16px',
      toSize: '36px',
    }
  )};
  overflow: auto;
  max-height: calc(100vh - 76px);

  @media (min-width: ${theme.breakpoints.SM}) {
    max-height: 100vh;
  }
`

const footerStyles = (theme: Theme): string => css`
  display: grid;
  align-content: start;
  align-items: start;
  background: ${theme.palette.WHITE};
  z-index: 99;

  background: ${theme.palette.WHITE};
  position: sticky;
  bottom: 0;
  padding: 1rem 0;

  @media (max-width: ${theme.breakpoints.SM}) {
    box-shadow: ${theme.shape.SHADOW_TOP};
  }
`

const stickyStyles = (theme: Theme): string => css`
  @media (min-width: ${theme.breakpoints.SM}) {
    position: sticky;
    top: 0;
  }
`

const CartWidget: FunctionComponent<CartProps> = ({
  cart,
  variant,
  setIncludeCutlery,
  onItemsChange,
  onSubmit,
  offersLoyaltyRewards = false,
  userRewards,
  onClearLoyalty,
  onClearCart,
  loyaltySession,
  onSelectReward,
  hasBoxShadow,
  loyaltyConfig,
  venue,
  operationalMenu,
  tbPaymentType,
  paymentMethod,
  showPayrixiFrameFF,
  currency,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModal()
  const theme = useTheme()

  const [isCheckoutButtonLoading, setIsCheckoutButtonLoading] = useState<boolean>(false)

  const orderMethod = cart.orderMethod ?? OrderMethod.pickup
  const venueXRefID = venue.venueXRefID
  const isPickup = orderMethod === OrderMethod.pickup
  const isDelivery = orderMethod === OrderMethod.delivery
  const isCheckoutPage = variant === CartWidgetVariant.CHECKOUT
  const cartContext = variant.toLowerCase()
  const isPickupAvailable = venue.venueOrderingMethods.some(
    (venueOrderingMethod) => venueOrderingMethod.orderMethod === OrderMethod.pickup
  )
  const isDeliveryAvailable = venue.venueOrderingMethods.some(
    (venueOrderingMethod) => venueOrderingMethod.orderMethod === OrderMethod.delivery
  )
  const { orderThrottlingLimitReached } = useMemo(
    () => getOrderMethodSettings(venue.venueOrderingMethods, orderMethod),
    [orderMethod, venue.venueOrderingMethods]
  )
  const isCartAvailable =
    venue.active &&
    ((isDelivery && isDeliveryAvailable) || (isPickup && isPickupAvailable)) &&
    (venue.isSchedulingAvailable || !orderThrottlingLimitReached)
  let isPaymentRedirectNoteVisible = false
  if (showPayrixiFrameFF) {
    isPaymentRedirectNoteVisible =
      paymentMethod === PaymentMethod.ONLINE &&
      isCheckoutPage &&
      tbPaymentType === TBPaymentType.WORLDPAY
  } else {
    isPaymentRedirectNoteVisible =
      paymentMethod === PaymentMethod.ONLINE &&
      isCheckoutPage &&
      (tbPaymentType === TBPaymentType.PAYRIX || tbPaymentType === TBPaymentType.WORLDPAY)
  }

  if (venue == null) {
    return <CircularLoader fontSize="16px" />
  }

  const renderMinimumSpentModal = (): void => {
    openModal(
      <PopupModalTemplate
        name="err_minimum_not_spent"
        callToAction={t('error.modal.cta')}
        message={t('error.modal.message_err_minimum_spent', {
          value: formatCurrency(cart.delivery?.minSubtotal),
        })}
        title={t('error.modal.title_err_minimum_spent')}
        onClose={closeModal}
        onSubmit={async () => {
          if (isCheckoutPage) {
            // if minimum is not spent, go back to menu page
            await router.push(
              '/[orderMethod]/[venueXRefID]/menu',
              `/${orderMethod.toLowerCase()}/${venueXRefID}/menu`
            )
          }
          closeModal()
        }}
      />,
      {
        variant: 'SMALL',
        forceSubmit: isCheckoutPage,
      }
    )
    setIsCheckoutButtonLoading(false)
  }

  const handleCartSubmit = (): void => {
    setIsCheckoutButtonLoading(true)

    if (isDelivery) {
      // check to make sure the discount amount + subtotal is greater than the minimum delivery spend. DiscountAmount is positive.
      const isMinimumSpent = Big(cart.delivery?.minSubtotal ?? '0').lt(
        Big(cart.bill.discountAmount ?? '0').plus(cart.bill.subtotal)
      )

      if (!isMinimumSpent) {
        renderMinimumSpentModal()
        return
      }
    }
    onSubmit()
    setIsCheckoutButtonLoading(false)
  }

  return (
    <div
      className={cx(containerStyles(theme), `${hasBoxShadow ? 'has-shadow' : ''}`)}
      data-test="cart-widget"
    >
      <div className={stickyStyles(theme)}>
        <div className={contentStyles(theme)}>
          {cart.items.length > 0 && isCartAvailable && (
            <CartHeader
              cart={{ bill: cart.bill, isCartLoading: cart.isCartLoading, orderMethod }}
              variant={variant}
              onClearCart={onClearCart}
              venueXRefID={venueXRefID}
            />
          )}
          <CartBody
            venue={{
              isASAPAvailable: venue.isASAPAvailable,
              isSchedulingAvailable: venue.isSchedulingAvailable,
              venueReopensAt: venue.venueReopensAt,
              disableTip: venue.disableTip,
              includeCutlery: venue.includeCutlery,
              active: venue.active,
              name: venue.name,
              orderThrottlingLimitReached,
              venueOrderingMethods: venue.venueOrderingMethods,
            }}
            cart={{
              bill: cart.bill,
              delivery: cart.delivery,
              isCartLoading: cart.isCartLoading,
              scheduledFor: cart.scheduledFor,
              items: cart.items,
              appliedLoyaltyRewardName: cart.appliedLoyaltyRewardName,
              orderMethod: orderMethod,
              discountedOrderItemID: cart.discountedOrderItemID,
            }}
            variant={variant}
            onItemsChange={onItemsChange}
            onChangeCutlery={setIncludeCutlery}
            userRewards={userRewards}
            loyaltySession={loyaltySession}
            onSelectReward={onSelectReward}
            offersLoyaltyRewards={offersLoyaltyRewards}
            onClearLoyalty={onClearLoyalty}
            loyaltyConfig={loyaltyConfig}
            operationalMenu={operationalMenu}
            currency={currency}
          />
          {cart.items.length > 0 && isCartAvailable && (
            <div className={footerStyles(theme)} data-test="cart-footer">
              <Button
                onClick={handleCartSubmit}
                data-test="cart-submit"
                fullWidth
                type="button"
                disabled={!venue.active}
                isLoading={isCheckoutButtonLoading}
                icon={isPaymentRedirectNoteVisible ? 'external_link' : undefined}
                center={isPaymentRedirectNoteVisible}
              >
                {t('cart_widget.footer.button', {
                  context: isPaymentRedirectNoteVisible ? 'redirect' : cartContext,
                })}
              </Button>
              {isPaymentRedirectNoteVisible && (
                <Text m="16px 0" data-test="redirect-note">
                  {t('cart_widget.footer.redirect_note')}
                </Text>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CartWidget
